import { /*differenceInSeconds, */format } from 'date-fns';
import { es } from 'date-fns/locale'
import { parse } from 'date-fns';

export const convertHexToRGB = (hex) => {
    // check if it's a rgba
    if (hex.match('rgba')) {
        let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
        return triplet;
    }

    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');

        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
};

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}

export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches;
    }
    return false;
}

export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches;
    }
    return false;
}

function currentYPosition(elm) {
    if (!window && !elm) {
        return;
    }
    if (elm) return elm.scrollTop;
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset;
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop;
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
}

function elmYPosition(elm) {
    var y = elm.offsetTop;
    var node = elm;
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent;
        y += node.offsetTop;
    }
    return y;
}

export function scrollTo(scrollableElement, elmID) {
    var elm = document.getElementById(elmID);

    if (!elmID || !elm) {
        return;
    }

    var startY = currentYPosition(scrollableElement);
    var stopY = elmYPosition(elm);

    var distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
        scrollTo(0, stopY);
        return;
    }
    var speed = Math.round(distance / 50);
    if (speed >= 20) speed = 20;
    var step = Math.round(distance / 25);
    var leapY = stopY > startY ? startY + step : startY - step;
    var timer = 0;
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout(
                (function (leapY) {
                    return () => {
                        scrollableElement.scrollTo(0, leapY);
                    };
                })(leapY),
                timer * speed
            );
            leapY += step;
            if (leapY > stopY) leapY = stopY;
            timer++;
        }
        return;
    }
    for (let i = startY; i > stopY; i -= step) {
        setTimeout(
            (function (leapY) {
                return () => {
                    scrollableElement.scrollTo(0, leapY);
                };
            })(leapY),
            timer * speed
        );
        leapY -= step;
        if (leapY < stopY) leapY = stopY;
        timer++;
    }
    return false;
}

export const flat = (array) => {
    var result = [];
    array.forEach(function (a) {
        result.push(a);
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children));
        }
    });
    return result;
};

export function longDatePPPPp(fecha) {
    return format(new Date(fecha), 'PPPPp', { locale: es });
};

export function longDatePPPP(fecha) {
    return format(new Date(fecha), 'PPPP', { locale: es });
};

export function shortDate(fecha) {
    return format(new Date(fecha), 'PP');
};

export function shortDatePPHHmm(fecha) {
    return format(new Date(fecha), 'PP HH:mm');
};

export function ddMMyyyytDate(fecha) {
    return format(new Date(fecha), 'yyyy-MM-dd');
};

export function shortHour(fecha) {
    return format(new Date(fecha), 'HH:mm:ss');
};

export const getAddress = async (latitude, longitude) => {
    try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_KEY_GOOGLE_MAPS}`);
        const data = await response.json();
        if (data.status === "OK" && data.results.length > 0)
            return data.results[0].formatted_address;
        else
            throw new Error("Failed to geocode coordinates")
    } catch (error) {
        console.error("Error geocoding coordinates:", error)
        return null;
    }
}

export function downloadFilesFromBase64(base64, fileName, typeFile) {
    const binaryString = atob(base64);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: `application/${typeFile}` });
    const url = URL.createObjectURL(blob);
    const element = document.createElement("a");
    element.href = url;
    element.download = fileName || `archivo.${typeFile}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(url);
}

export const getFileAsPhotoToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve({
                deleteByUser: false,
                extension: file.name.split('.').pop(),
                fileName: file.name,
                imageB64: reader.result.split(",")[1],
                isProfilePhoto: false,
                repository: false
            });
        };
        reader.onerror = (error) => {
            console.log("er", error)
            reject(error);
        };
    });
}

export function base64ToUint8Array(base64String) {
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const uint8Array = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    return uint8Array;
};

export function getAvailabilityCode(day, response) {
    const codeDetail = `code${day}`;
    const start = response.availabilities.find(a => a.codeDetail === day.toUpperCase())?.startTime;
    const end = response.availabilities.find(a => a.codeDetail === day.toUpperCase())?.endTime;
    const startTime = start ? parse(start, 'HH:mm:ss', new Date()) : new Date().setHours(9, 0, 0);
    const endTime = end ? parse(end, 'HH:mm:ss', new Date()) : new Date().setHours(18, 0, 0);
    return { [`${codeDetail}Start`]: startTime, [`${codeDetail}End`]: endTime, };
}